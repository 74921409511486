import Vue from 'vue/dist/vue.js'

const DEV = !window.location.href.includes("applesauce.cole.ws")
const inapp = (new URL(location.href)).search == "?app"

const music = MusicKit.configure({
	developerToken: 'eyJhbGciOiJFUzI1NiIsImtpZCI6IjZIQzdDUVpEVTUiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJQNlBWMlI5NDQzIiwiaWF0IjoxNjg5MTQ5OTA0LCJleHAiOjE3MDQ3MDE5MDR9.7sgoo1fpp7rXA5Ja7w-99-ZKvLnrY33GWafesdEnP67b4iv6t8mqtyei8UPAyT05dVfrDkplk9hvfsGEMQ8aoQ',
	app: {
		name: 'iJuke',
		build: '1978.4.1'
	}
});

window.onhashchange = () => location.reload()
window.music = music
var ws, reconnects=-1;
setUpSocket()

Vue.component('song', {
  props: ['song', 'prefs'],
	template: `
	<div class="song" :disabled="!isAllowed()" tabindex="0">
		<img v-if="song.artwork" :src="MusicKit.formatArtworkURL(song.artwork, 50, 50)">
		<img v-if="!song.artwork">
		<div>
			<span>{{song.name || song.title || 'Not Playing'}}<span class="dim" v-if="song.isExplicitItem || song.contentRating=='explicit'">&nbsp;&#x1F174;</span></span><br>
			<span v-if="song.artistName" class="dim">{{song.artistName}} &middot; {{song.albumName}}</span>
		</div>
		<span class="time">
			{{Math.floor(song.durationInMillis/1000/60)}}:{{String(Math.floor(song.durationInMillis/1000 % 60)).padStart(2,0)}}
		</span>
	</div>
	`,
	data: function() {return {
		MusicKit: MusicKit
	}},
	methods: {
		isAllowed() {
			if (this.prefs) {
				if (this.prefs.blockExplicit && this.song.contentRating == 'explicit') return false;
				if (this.prefs.songlength > 0 && (this.song.durationInMillis/1000) >= this.prefs.songlength*60) return false;
				for (var genre in this.prefs.genres) {
					if (this.song.genreNames.includes(genre) && !this.prefs.genres[genre]) return false
				}
			}
			return true
		}
	}
})

var app = new Vue({
	el: '#app',
	data: {
		search: {results: [], hints: [], text: ""},
		isplaying: false,
		queue: [],
		nowplaying: new Object(),
		progress: 0,
		bigplayer: false,
		music: music,
		library: {playlists:[], albums:[], recent:[], heavy: []},
		preferences: {name: "Applesauce Session"},
		ishome: location.hash.slice(1)=="",
		inapp: inapp,
		qrlink: encodeURIComponent(window.location.href),
		showIntro: !inapp //&& !DEV
	},
	methods: {
		async share() {
			try {
				await navigator.share({
					title: this.preferences.name,
					text: "Add songs to "+this.preferences.name+" on Applesauce!",
					url: location.href
				})
			} catch {
				await navigator.clipboard.writeText(location.href)
				popup("Copied URL to clipboard!")
			}
		},
		closeIntro() {
			this.showIntro = false
		},
		setHint(hint) {
			this.search.text = hint;
			this.search.hints = [];
			this.updateSearchResults()
		},
		updateSearchResults(text) {
			// console.log(text, typeof text)
			// if (text) this.search.text = text
			if (this.search.text == "") return
			let thisSearch = this.search.text;
			setTimeout(()=>{this.search.hints = []}, 500);
			this.search.results = [];
			this.search.loading = true;
			music.api.search(this.search.text, {limit: 25}).then((results) => {
				if (this.search.text != thisSearch) {return}
				console.log(results.songs.data)
				this.search.loading = false;
				this.search.results = results.songs.data
			})
		},
		updateSearchHints() {
			console.log(this.search.text == "")
			if (this.search.text == "") {
				this.search.hints = [];
				return;
			}
			music.api.searchHints(this.search.text).then(results => {
				this.search.hints = results.terms
			})
		},
		getArtwork(artwork, size) {
			if (!artwork) return
			return MusicKit.formatArtworkURL(artwork, size, size)
		},
		addSong(song) {
			if (song.attributes.name.toLowerCase().includes("never gonna give you up") && this.preferences.hiderickrolls) {
				confirm("the host has disabled rickrolls!")
				window.location.href = "https://youtu.be/dQw4w9WgXcQ"
				return
			}
			var id = song.id
			if (!song.id.match(/\d+/g)) id = song.attributes.playParams.catalogId
			ws.send(id + "")
			popup("song added!")
		},
		skip() {
			ws.send("skip")
			popup("skip count updated!")
		},
		auth() {music.authorize()},
		updateLibraryPlaylists() {
			if (this.library.playlists.length>0) return
			console.log("updated playlists")
			// this.library.playlists = [null]
			music.api.library.playlists(null, {limit: 100}).then(playlists => playlists.forEach(playlist => {
				this.library.playlists.push({id: playlist.id, name: playlist.attributes.name, tracks: []})
				// this.library.playlists[playlist.id] = {name: playlist.attributes.name, tracks: []}
			}))
		},
		updateLibraryPlaylist(index) {
			let i = this.library.playlists[index].id
			music.api.library.playlistRelationship(i, "tracks", {limit: 100}).then(songs => {
				this.library.playlists[index].tracks = songs
			})
		},
		updateLibraryAlbums() {
			if (this.library.albums.length>0) return
			console.log("updated albums")
			music.api.library.albums(null, {limit: 100}).then(albums => albums.forEach(album => {
				this.library.albums.push({id: album.id, name: album.attributes.name, tracks: []})
			}))
		},
		updateLibraryAlbum(index) {
			let i = this.library.albums[index].id
			music.api.library.albumRelationship(i, "tracks", {limit: 100}).then(songs => {
				this.library.albums[index].tracks = songs
			})
		},
		updateLibraryRecent() {
			console.log("updated recently played")
			music.api.request("/me/recent/played/tracks").then(songs => {this.library.recent = songs})
			// music.api.library.playlists(null, {limit: 100}).then(playlists => playlists.forEach(artist => {
			// 	music.api.library.artistRelationship(artist.id, "tracks", {limit: 100}).then(songs => {
			// 		playlist.tracks = songs
			// 		this.library.playlists.push(playlist)
			// 	})
			// }))
		},
		updateLibraryHeavyRotation() {
			music.api.request("/me/history/heavy-rotation").then(songs => {
				this.library.heavy = songs
			})
		},
		addToLibrary() {
			music.api.addToLibrary(this.nowplaying.id).then(()=>popup("Added to library!"))
		},
		hlHint(hint) {
			let text = this.search.text.toLowerCase();
			return hint.replace(text, "<span class=fullcolor>"+text+"</span>")
		}
	}
})
window.app = app
window.addEventListener("load", _ => {
	if (music.isAuthorized)
		setTimeout(app.updateLibraryHeavyRotation, 1000)
})
music.addEventListener("queueItemsDidChange", (e) => {
	app.queue = e
})
music.addEventListener("mediaItemDidChange", (e) => {
	app.nowplaying = e.item
	console.log(e.item)
})
music.addEventListener("playbackStateDidChange", e => {
	app.isplaying = e.state == MusicKit.PlaybackStates.playing
});
music.addEventListener("playbackProgressDidChange", e => {
	app.progress = e.progress * 100
})
music.addEventListener("authorizationStatusDidChange", e => {
	app.updateLibraryHeavyRotation()
})
function setUpSocket() {
	if (location.hash == "") return
	if (reconnects > 100) {
		console.error("can't reconnect to server :(")
		return
	}
	reconnects++
	console.warn("connecting to server... (#"+ reconnects +")")

	ws = new WebSocket((DEV ? "ws://laptop.cole.ws:81/" : "wss://applecore.ridgeback.cole.ws:443/") + location.hash.slice(1));

	ws.onmessage = async (e) => {
		let message = JSON.parse(e.data)
		switch (message.type) {
			case "preferences":
				app.preferences = JSON.parse(message.data)
				break;
			case "nowplaying":
				app.nowplaying = (await music.api.song(message.data)).attributes
				app.nowplaying.id = message.data
				break;
			default:
				console.error("unhandled message:", message)
				break;
		}
	}
	ws.onclose = (e) => {
		setTimeout(setUpSocket, 500)
	}
}
